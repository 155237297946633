import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { colors, fontSizes } from "../structure/theme"
import PaymentButton from "../payment/paymentButton.component"
import { ButtonPhone } from "../campaign/bannerProductButton.component"
import { useTranslation } from "../translation/translate.component"
import { flexCenter } from "../structure/theme/styles.component"

const Sticky = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 15px 20px 10px 20px;
  background-color: white;
  border-top: 1px solid ${colors(`grey.g400`)};
  box-shadow: 0 -2px 5px ${colors(`grey.g400`)};

  ${breakpoint(`medium`)`
    display: none
  `}
`

const ButtonContainer = styled.div`
  width: 90%;
  margin: auto;
  max-width: 360px;
`

const Message = styled.div`
  ${flexCenter};
  font-size: ${fontSizes(`small`)};
  color: ${colors(`grey.g800`)};
`

const ButtonWebWrapper = styled.div`
  margin-bottom: 5px;
`

export default function ProductPageSticky ({
  product,
  phoneVariant = false,
  phoneNumber = ``,
  bannerButtonBgColor = ``,
}) {
  const { t } = useTranslation()

  return (
    <Sticky data-testid="product_page_sticky">
      <ButtonContainer>
        {phoneVariant ? (
          <>
            <ButtonWebWrapper>
              <PaymentButton product={product} buttonsColor={bannerButtonBgColor} phoneVariant={phoneVariant} />
            </ButtonWebWrapper>

            <ButtonPhone href={`tel:${phoneNumber}`} buttonsColor={bannerButtonBgColor} phoneNumber={phoneNumber} />
          </>
        ) : (
          <PaymentButton product={product} />
        )}
      </ButtonContainer>
      <Message>{t(`common:product.section_sticky_message`)}</Message>
    </Sticky>
  )
}

ProductPageSticky.propTypes = {
  product: PropTypes.object,
  phoneVariant: PropTypes.bool,
  phoneNumber: PropTypes.string,
  bannerButtonBgColor: PropTypes.string,
}
